import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
//import styles from '../components/base.css'

class about extends React.Component{
    render(){
        const siteTitle = ('Kiwok - About')

        return (
            <Layout location={this.props.location} >
            <div className="containerWrapper">
              <div style={{ background: '#fff' }}>
                <Helmet title={siteTitle} />
                
                <div className="wrapperPage">
                    <h2>About us</h2>
                  <p>Founded in 2003, Kiwok a MedTech company has developed BodyKom, a Remote Patient Monitoring service for ECG. The solution is based on a mobile accessed, body close sensor technology and cloud service. BodyKom measures data, compares data to rules, triggers activities on deviation from the rules and distributes diagnosis data to healthcare providers. In short, Kiwok enables patients to be mobile and perform everyday activities, yet still be under medical observation. Since the product enables healthcare providers to monitor patients on a long-term, continuous, real-time basis, BodyKom results in earlier diagnosis, more effective treatment, better follow-up, higher care quality and hence lower costs. Kiwok is granted method patents in the US and Korea. The product is developed in cooperation with Karolinska University Hospital in Sweden.</p>
                </div>
              </div>
            </div>
          </Layout>
        )
    }
}

export default about
